/**
 * @generated SignedSource<<77e1e24ce76eb16e0c481345e4db43aa>>
 * @relayHash 20814f831baa73b11de8822cc6c91777
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/504.0.0-2025-04-03T17:05:40.233Z/launchLoginMutationQuery

import { ConcreteRequest } from 'relay-runtime';
export type launchLoginMutationQuery$variables = {
  userCountryCode: string;
};
export type launchLoginMutationQuery$data = {
  readonly viewer: {
    readonly appDownloadDisplayInfo: {
      readonly isAppDownloadApplicable: boolean | null;
    } | null;
    readonly gdprDisplayInfo: {
      readonly isGdprApplicable: boolean | null;
    } | null;
  };
};
export type launchLoginMutationQuery = {
  response: launchLoginMutationQuery$data;
  variables: launchLoginMutationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userCountryCode"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "countryCode",
    "variableName": "userCountryCode"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "gdprDisplayInfo",
  "kind": "LinkedField",
  "name": "gdprDisplayInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "isGdprApplicable",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "AppDownloadDisplayInfo",
  "kind": "LinkedField",
  "name": "appDownloadDisplayInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": (v1/*: any*/),
      "kind": "ScalarField",
      "name": "isAppDownloadApplicable",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "launchLoginMutationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "launchLoginMutationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/504.0.0-2025-04-03T17:05:40.233Z/launchLoginMutationQuery",
    "metadata": {},
    "name": "launchLoginMutationQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3b96ab07a7241efa04eeddf2e838850f";

export default node;
