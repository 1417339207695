/**
 * @generated SignedSource<<975c5b06e770f9a5d7e28241d1af6a4e>>
 * @relayHash a479f2621770a5349814c5c684e1de09
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/504.0.0-2025-04-03T17:05:40.233Z/editAccountPartialShippingAddressMutation

import { ConcreteRequest } from 'relay-runtime';
export type addPartialShippingAddressInput = {
  address: AddressInput;
  clientMutationId?: string | null;
  userId: string;
};
export type AddressInput = {
  active?: boolean | null;
  addressBookMember?: boolean | null;
  city?: string | null;
  company?: string | null;
  contact?: string | null;
  country?: string | null;
  firstName?: string | null;
  label?: string | null;
  lastName?: string | null;
  line1?: string | null;
  line2?: string | null;
  phoneNumber?: string | null;
  state?: string | null;
  zipCode?: string | null;
};
export type editAccountPartialShippingAddressMutation$variables = {
  input: addPartialShippingAddressInput;
};
export type editAccountPartialShippingAddressMutation$data = {
  readonly addPartialShippingAddress: {
    readonly address: {
      readonly country: string | null;
    } | null;
  } | null;
};
export type editAccountPartialShippingAddressMutation = {
  response: editAccountPartialShippingAddressMutation$data;
  variables: editAccountPartialShippingAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "country",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAccountPartialShippingAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "addPartialShippingAddressPayload",
        "kind": "LinkedField",
        "name": "addPartialShippingAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAccountPartialShippingAddressMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "addPartialShippingAddressPayload",
        "kind": "LinkedField",
        "name": "addPartialShippingAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Address",
            "kind": "LinkedField",
            "name": "address",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/504.0.0-2025-04-03T17:05:40.233Z/editAccountPartialShippingAddressMutation",
    "metadata": {},
    "name": "editAccountPartialShippingAddressMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "0648d5d4468a0aac19dac4765ef3a741";

export default node;
